import { CodegenConfig } from '@graphql-codegen/cli';

const endpointOverride = process.env.CONTENTFUL_GRAPHQL_ENDPOINT;
const contentfulEnvironmentSuffix = process.env.CONTENTFUL_ENVIRONMENT_ID
	? `/environments/${process.env.CONTENTFUL_ENVIRONMENT_ID}`
	: '';
const productionEndpoint = 'https://graphql.contentful.com/content/v1/spaces';
export const contentfulGraphQLURL = `${endpointOverride || productionEndpoint}/${
	process.env.CONTENTFUL_SPACE_ID
}${contentfulEnvironmentSuffix}`;

export const enterpriseAPIGraphQLURL = `${process.env.EAPI_GRAPHQL_URL}`;
export const beffeGraphQLURL = `${process.env.BEFFE_GRAPHQL_URL}`;
export const config: CodegenConfig = {
	overwrite: true,
	ignoreNoDocuments: true,
	schema: [
		{
			[contentfulGraphQLURL || '']: {
				headers: {
					Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
				},
			},
		},
		{
			[enterpriseAPIGraphQLURL || '']: {
				headers: {
					'user-agent': 'TeamTAG/GraphQL-CodeGen 1.0 (2023.11)',
					'x-client-application': 'web-graphql-codegen-cli',
					'x-graphql-introspection-key': `${process.env.EAPI_GRAPHQL_ACCESS_TOKEN}`,
				},
			},
		},
		{
			[beffeGraphQLURL || '']: {
				headers: {
					'user-agent': 'TeamTAG/GraphQL-CodeGen 1.0 (2025.02)',
					'x-client-application': 'tag_dev',
					'x-beffe-introspection-key': `${process.env.BEFFE_GRAPHQL_INTROSPECTION_TOKEN as string}`,
				},
			},
		},
	],
	generates: {
		'src/lib/__generated/graphql.schema.json': {
			plugins: ['introspection'],
		},
		'src/lib/__generated/graphql.schema.graphql': {
			plugins: ['schema-ast'],
		},
		'src/lib/__generated/sdk.ts': {
			documents: ['src/lib/graphql/**/*.graphql'],
			plugins: [
				'typescript',
				'typescript-operations',
				'typescript-urql',
				{
					add: {
						content: `/* eslint-disable */
// @ts-nocheck`,
					},
				},
			],
			config: {
				rawRequest: false,
				inlineFragmentTypes: 'combine',
				skipTypename: false,
				exportFragmentSpreadSubTypes: true,
				dedupeFragments: true,
				preResolveTypes: true,
				enumsAsTypes: true,
			},
		},
	},
};

export default config;
